const toggler = document.querySelector('.aside-toggler');
const wrapper = document.querySelector('#aside-wrapper');
const header = document.querySelector('header');
const body = document.querySelector('body');

const cssClass = {
    show: 'show',
    asideShown: 'aside-shown',
};

/**
 * Shows aside
 */
const show = () => {
    wrapper.classList.add(cssClass.show);
    toggler.setAttribute('aria-expanded', true);
    header.classList.add(cssClass.asideShown);
    body.classList.add(cssClass.asideShown);
};

/**
 * Hides aside
 */
const hide = () => {
    wrapper.classList.remove(cssClass.show);
    toggler.setAttribute('aria-expanded', false);
    header.classList.remove(cssClass.asideShown);
    body.classList.remove(cssClass.asideShown);
};

/**
 * Hides shown menu when click outside
 * @param {Event} e
 */
const handleOutsideClick = (e) => {
    if (!toggler.contains(e.target) && !wrapper.contains(e.target)) hide();
};

/**
 * Handles click on aside toggler
 */
const handleClickOnToggler = () => {
    // aside is hidden, show it
    if (toggler.getAttribute('aria-expanded') === 'false') {
        show();
        return;
    }
    // hide aside
    hide();
};

// Event handlers
document.addEventListener('click', (e) => {
    handleOutsideClick(e);
});
toggler.addEventListener('click', handleClickOnToggler);
